import React from 'react'
import styled from 'styled-components'
import { siteMetadata } from '../../../site.config'
import { Link } from 'gatsby-plugin-react-i18next'

const StyledLink = styled(Link)`
    font-weight: 900;

    text-decoration: none;

    strong {
        font-weight: 900;
    }
    small:nth-of-type(1) {
        color: #24c14f;
    }
    small:nth-of-type(2) {
        display: inline-block;
        font-size: 0.7rem;
        text-align: center;
        width: 100%;
    }
`

const JurigoLogoTypo = ({ ...props }) => (
    <>
        {process.env.NODE_ENV === 'development' ? (
            <span style={{ color: '#50ff50', fontWeight: 900 }}>DEV </span>
        ) : null}
        <StyledLink
            to={props.selfLink ? '' : '/'}
            activeClassName="active"
            {...props}
        >
            {siteMetadata.siteName}
            <small>.ca</small>
            <br />
            <small>Propulsé par JuriGo.ca</small>
        </StyledLink>
    </>
)

export default JurigoLogoTypo
