import React from 'react'
import { Grid, Button } from '@material-ui/core'

import styled from 'styled-components'
import { Link } from 'gatsby'

const currentYear = new Date().getFullYear()

const StyledFooter = styled.footer`
    margin: ${(props) => (props.lowFootprint ? '0 auto' : '200px auto 100px')};
    max-width: 1100px;
    padding: 20px;
    text-align: center;
    h2 {
        font-size: 1.7rem;
        font-weight: 600;
        text-align: left;
        text-transform: uppercase;
        width: 100%;
    }
    h2,
    h3 {
        text-align: left;
        text-transform: uppercase;
    }
    a {
        color: black;
        &:visited {
            color: black;
        }
    }
    ul,
    a {
        text-align: left;
    }
    ul {
        line-height: 25px;
        list-style: none;
    }
`

const Footer = ({ lowFootprint, sitename = '' }) => {
    return (
        <StyledFooter lowFootprint={lowFootprint}>
            {!lowFootprint ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {sitename}
                    </Grid>
                    <Grid item xs={12} sm={12}></Grid>
                    <Grid item xs={12} sm={6}></Grid>
                </Grid>
            ) : null}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                    <span>
                        Copyright © {currentYear} {sitename}.
                    </span>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Link to="/recette-avocat-crevette/">
                        Recettes d'avocats et crevettes
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                    {1 === 0 && (
                        <span>
                            <Link to="/termes-et-conditions">
                                Termes et Conditions / Politique de
                                Confidentialité
                            </Link>
                        </span>
                    )}
                </Grid>
            </Grid>
        </StyledFooter>
    )
}

export default Footer
