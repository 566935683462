module.exports = {
    siteMetadata: {
        siteUrl: 'https://recetteavocat.ca/',
        siteName: 'RecetteAvocat',
        title: "Les meilleures recettes d'avocats",
        description:
            "Explorez un univers culinaire riche en saveurs avec notre site dédié aux recettes d'avocat. Que vous soyez végétarien, végétalien ou simplement à la recherche d'idées créatives pour mettre en valeur cet ingrédient polyvalent, nous avons des recettes pour tous les goûts et toutes les occasions.",

        gtag: 'G-X357XTWVWP',
        gads: '',
        ga: '',
        splashImgName: 'avocado_lawyer.jpg',
    },
    defaultFormConfig: {
        lawType: '',
        description: '',
        hideLawType: true,
        hideOthersInvolved: true,
        hideDescription: true,
        source: '',
        othersInvolved: '',
        forceEmail: true,
        mobileBackground: true,
        simple: true,
    },
    content: {
        find: '',
        answer: '',
        lawyerFor: '',
    },
}
