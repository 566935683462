import React from 'react'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { Link } from 'gatsby'
import JuriGoLogo from '../JurigoLogoTypo'

const GridItem = styled(Grid)`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 30px;

    @media (max-width: 667px) {
        :nth-child(1) {
            padding: 10px 0 0;
            font-size: 0.85rem;
            a,
            a:visited {
                color: black;
            }
        }
        :nth-child(2) {
            padding: 10px 0 0;
            strong {
                font-size: 0.85rem;
            }
        }

        :nth-child(3) {
            display: none;
        }
        max-width: 100%;
    }
`
const CenterGrid = styled(GridItem)`
    text-align: center;
    @media (max-width: 767px), (orientation: portrait) {
        display: none;
    }
`

const Header = ({ noPhones }) => (
    <header style={{ maxWidth: '100%', padding: '5px 10px' }}>
        <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
            <GridItem item xs={12} sm={4}>
                <JuriGoLogo />
            </GridItem>
            <CenterGrid item xs={0} sm={4}>
                <strong>Trouvez une recette d'avocats en 30 secondes!</strong>
            </CenterGrid>
            {noPhones ? null : (
                <GridItem
                    item
                    xs={12}
                    sm={4}
                    style={{ flexDirection: 'column' }}
                ></GridItem>
            )}
        </Grid>
    </header>
)

export default Header
