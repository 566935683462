import React from 'react'
import styled from 'styled-components'
import Form from '../../../../src/containers/LandingForm'
import { Grid } from '@material-ui/core'
import { secondary } from '../../../../src/constants/theme'
import Img from 'gatsby-image'
import { useStaticQuery, graphql, Link } from 'gatsby'
import ButtonWithArrow from '../../../../src/components/ButtonWithArrow'
import { content } from '../../../site.config'
const Main = styled.main`
    margin: 0 auto;
    max-width: 1160px;
    padding: 0 12px;
    min-height: 80vh;
    display: flex;
    p.MuiFormHelperText-root.Mui-error {
        font-size: 0.6rem;
        text-align: left;
    }
    h1 > span {
        color: ${secondary};
    }
    .h1 {
        display: block;
        font-weight: 700;
        font-size: 3rem;
        line-height: 1.05;
        text-align: center;
    }
`
const StyledImg = styled(Img)`
    height: 120vh !important;
    picture {
        @media (max-width: 767px) {
            img {
                object-position: ${(props) =>
                    props.alt ? '-60vw center !important' : 'center center'};
            }
        }
    }
`
const HeroImageWrapper = styled.div`
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 80vh;
    img {
        height: 100%;
        width: 100%;
    }
    @media (max-width: 767px) {
        // height: 130vw;
    }
    &:before {
        background: #1e551aa3;
        content: ' ';
        position: absolute;
        z-index: 1;
        height: 80vh;
        width: 100%;
    }

    @media (max-width: 767px), (orientation: portrait) {
        &:before {
            height: 100%;
        }
        img {
            height: 100%;
        }
    }
`
const TopGrid = styled(Grid)`
    color: white;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    h1 {
        font-weight: 700;
        font-size: 3rem;
        line-height: 1.05;
    }
    h2 {
        font-weight: 600;
        font-size: 2rem;
    }
    h1,
    h2,
    p {
        text-align: center;
    }
    p {
        font-size: 1.2rem;
    }

    a > div {
        margin: 20px auto 0;
        max-width: 400px;
        > button {
            font-size: 1.8rem;
        }
    }
    @media (max-width: 767px), (orientation: portrait) {
        h1 {
            font-size: 2rem;
        }
        h2 {
            font-size: 1.2rem;
        }
        > div {
            :nth-child(1) {
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin-top: 15px;
            }
            :nth-child(2) {
                //margin-top: 80px;
                //background-color: white;
            }
        }
        a > div {
            margin: 20px auto 0;
            max-width: 400px;
            > button {
                font-size: 1.4rem;
            }
        }
    }
`
const TopFormContainer = styled(Grid)`
    > div > div {
        min-height: 400px;
        padding: 60px 20px;
        @media (max-width: 767px) {
            padding: 12px;
        }
    }
`
const Hero = ({ formProps, alternativeImage, to, useH1 }) => {
    const data = useStaticQuery(graphql`
        query {
            main: file(relativePath: { eq: "avocado_lawyer.jpg" }) {
                childImageSharp {
                    # Specify the image processing specifications right in the query.
                    # Makes it trivial to update as your page's design changes.
                    fluid(maxWidth: 1993, quality: 40) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            alternative: file(relativePath: { eq: "avocado_lawyer.jpg" }) {
                childImageSharp {
                    # Specify the image processing specifications right in the query.
                    # Makes it trivial to update as your page's design changes.
                    fluid(maxWidth: 1993, quality: 40) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <>
            <HeroImageWrapper>
                {alternativeImage ? (
                    <StyledImg
                        fluid={data.alternative.childImageSharp.fluid}
                        style={{ postion: 'initial' }}
                        alt={true}
                    />
                ) : (
                    <StyledImg
                        fluid={data.main.childImageSharp.fluid}
                        style={{ postion: 'initial' }}
                    />
                )}
            </HeroImageWrapper>
            <Main>
                <TopGrid container spacing={2}>
                    <Grid item sm={12} md={!formProps ? 12 : 6}>
                        <span className="h1">
                            Trouvez la meilleure recette d'avocats en 30
                            secondes
                        </span>
                        <p>
                            Explorez un univers culinaire riche en saveurs avec
                            notre site dédié aux recettes d'avocat. Que vous
                            soyez végétarien, végétalien ou simplement à la
                            recherche d'idées créatives pour mettre en valeur
                            cet ingrédient polyvalent, nous avons des recettes
                            pour tous les goûts et toutes les occasions.
                        </p>
                    </Grid>
                </TopGrid>
            </Main>
        </>
    )
}
export default Hero
